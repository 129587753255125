import {connect} from 'react-redux'
import {GaFormComponent} from "../component/ga.form";
import {switchLoginMode} from "../../../action/login";
import {performGoogleAuthentication} from "../action/ga.form";
import {MODE_LOGIN_FORM} from "../../../constant/login";
import formData from "../../../../../common/tools/form.data";

const mapDispatchToProps = (dispatch, ownProps) => ({
    performCode: (event) => {
        dispatch(performGoogleAuthentication(formData(event)))
    },
    back: () => {
        dispatch(switchLoginMode(MODE_LOGIN_FORM))
    }
});

const mapStateToProps = (state, ownProps) => ({
    ...state.gaFormReducer
});

export const GaForm = connect(
    mapStateToProps, mapDispatchToProps
)(GaFormComponent);