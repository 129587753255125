import {createAction, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import Config from "../../common/config";
import convertErrors from "../../../common/tools/convert.errors";
import {handleAuthResult} from "../common/handle.auth.result";

/**
 * Switch login mode
 */
export const switchLoginMode = createAction('login/switchMode');

/**
 * Load next step (after login by code)
 */
export const nextStep = createAsyncThunk('login/next/step', async (data, thunkAPI) => {
    try {
        const resp = await axios.get(Config.path.login.next_step);
        handleAuthResult(resp.data, thunkAPI);

        return resp.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(convertErrors(err?.response?.data));
    }
});