import {connect} from 'react-redux'
import {LoginFormComponent} from "../component/login.form";
import {performLogin} from "../action/login.form";
import formData from "../../../../../common/tools/form.data";

const mapDispatchToProps = (dispatch, ownProps) => ({
    performLogin: (event) => {
        dispatch(performLogin(formData(event)))
    }
});

const mapStateToProps = (state, ownProps) => ({
    ...state.loginFormReducer
});

export const LoginForm = connect(
    mapStateToProps, mapDispatchToProps
)(LoginFormComponent);