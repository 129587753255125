import React from 'react'
import InputField from "../../../../../common/component/fields/input.field";
import {Visible} from "../../../../../common/component/visible";
import {_t} from "../../../../../../js/translation";

export const GaSetupComponent = (props) => (
    <section className="login-form__wrapper login-form--ga login-form--ga-setup">
        <div className="login-form__content">
            <form onSubmit={props.performSetup}>
                <p className={'login-form__text p'}>{_t('Setup 2FA code (Google Authenticator)')}</p>

                <Visible visible={!props.secretCode}>
                    <div>Loading...</div>
                </Visible>
                <Visible visible={props.secretCode}>
                    {props.formError &&
                        <div className="alert alert-danger">{props.formError}</div>
                    }

                    <div className="login-form__content-picture-ga">
                        <img src={props.qrCodeUrl} />
                    </div>

                <InputField name="code" label="New security code" errors={props.errors.code} />
                <input type="hidden" name="type" value="ga" />

                    <div className="login-form__btn-bar login-form__btn-bar--ga">
                        <button disabled={props.loading} type="submit" className="btn btn--green btn--continue-login">{_t('Confirm')}</button>
                        <button type="button" className="btn btn-link btn-link--grey" onClick={props.back}>{_t('Back')}</button>
                    </div>
                </Visible>
            </form>
        </div>
    </section>
)