import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import Config from "../../../../common/config";
import convertErrors from "../../../../../common/tools/convert.errors";
import {handleAuthResult} from "../../../common/handle.auth.result";

export const performPasswordSetup = createAsyncThunk('password/setup/perform', async (data, thunkAPI) => {
    try {
        const resp = await axios.post(Config.path.login.setup_password, data);
        handleAuthResult(resp.data, thunkAPI);

        return resp.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(convertErrors(err?.response?.data));
    }
});