import {
    MODE_2FA,
    MODE_2FA_SETUP,
    MODE_AUTHENTICATED,
    MODE_GA_2FA_FORM,
    MODE_GA_2FA_SETUP,
    MODE_PASSWORD_SETUP,
    TYPE_GA
} from "../constant/login";
import {switchLoginMode} from "../action/login";
import Config from "../../common/config";

export const handleAuthResult = function (data, thunkAPI) {
    if (data.mode === MODE_AUTHENTICATED) {
        location.href = Config.path.panel_index;
    }
    if (data.mode === MODE_PASSWORD_SETUP) {
        thunkAPI.dispatch(switchLoginMode(MODE_PASSWORD_SETUP));
    }
    if (data.mode === MODE_2FA) {
        switch (data.secondFactorType) {
            case TYPE_GA:
                thunkAPI.dispatch(switchLoginMode(MODE_GA_2FA_FORM));
                break;
        }
    }
    if (data.mode === MODE_2FA_SETUP) {
        switch (data.secondFactorType) {
            case TYPE_GA:
                thunkAPI.dispatch(switchLoginMode(MODE_GA_2FA_SETUP));
                break;
        }
    }
}