import {createReducer} from "@reduxjs/toolkit";
import {buildFormReducer, formInitialState} from "../../../../../common/tools/form/form.reducer.builder";
import {performPasswordSetup} from "../action/password_setup.form";

const initialState = {
    ...formInitialState()
};

export const passwordSetupFormReducer = createReducer(initialState, (builder) => {
    buildFormReducer(builder, performPasswordSetup);
});