import React from 'react'
import {render} from 'react-dom'
import {Provider} from 'react-redux'
import {rootReducer} from './login/store/reducer'
import {configureStore} from "@reduxjs/toolkit";
import App from './login/app'

import './common/axios.init';
import {nextStep} from "./login/action/login";

const store = configureStore({
    reducer: rootReducer
});

const loginMode = document.getElementById('root').dataset.mode;
if (loginMode === 'use_code') {
    store.dispatch(nextStep());
}

render(
    <Provider store={store}>
        <App/>
    </Provider>,
    document.getElementById('root')
)