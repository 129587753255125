import {createReducer} from "@reduxjs/toolkit";
import {performLogin} from "../action/login.form";
import {buildFormReducer, formInitialState} from "../../../../../common/tools/form/form.reducer.builder";

const initialState = {
    ...formInitialState()
};

export const loginFormReducer = createReducer(initialState, (builder) => {
    buildFormReducer(builder, performLogin);
});