import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import Config from "../../../../common/config";
import convertErrors from "../../../../../common/tools/convert.errors";

export const performGoogleAuthentication = createAsyncThunk('ga/form/perform', async (data, thunkAPI) => {
    try {
        const resp = await axios.post(Config.path.login.perform_2fa, data);

        if (resp.data.authenticated) {
            location.href = Config.path.panel_index;
        }

        return resp.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(convertErrors(err?.response?.data));
    }
});