import jsonpointer from "jsonpointer";

const convertErrors = (errors) => {
    const res = {
        message: null,
        fields: {}
    };
    if (errors && errors.message) {
        res.message = errors.message;
    }

    if (errors && errors.data && errors.data.errors) {
        errors.data.errors.forEach((el) => {
            jsonpointer.set(res.fields, '/' + el.path, [el.error]);
        });
    }

    return res;
}

export default convertErrors;