import {combineReducers} from 'redux'
import {loginReducer} from "./login"
import {loginFormReducer} from "../../modules/login/reducer/login.form"
import {passwordSetupFormReducer} from "../../modules/password_setup/reducer/password_setup.form"
import {gaFormReducer} from "../../modules/ga/reducer/ga.form"
import {gaSetupReducer} from "../../modules/ga/reducer/ga.setup"

export const rootReducer = combineReducers({
    loginReducer,
    loginFormReducer,
    passwordSetupFormReducer,
    gaFormReducer,
    gaSetupReducer,
});