import {createReducer} from "@reduxjs/toolkit";
import {loadGoogleAuthenticatorSecret, performSetupGoogleAuthenticator} from "../action/ga.setup";
import {buildFormReducer, formInitialState} from "../../../../../common/tools/form/form.reducer.builder";

const initialState = {
    secretCode: null,
    qrCodeUrl: null, ...formInitialState(),
};

export const gaSetupReducer = createReducer(initialState, (builder) => {
    buildFormReducer(builder, performSetupGoogleAuthenticator);

    builder
        .addCase(loadGoogleAuthenticatorSecret.fulfilled, (state, action) => {
            state.loading = false;
            state.errors = {};
            state.formError = null;
            state.secretCode = action.payload.secret;
            state.qrCodeUrl = action.payload.qrCodeUrl;
        })
        .addCase(loadGoogleAuthenticatorSecret.rejected, (state, action) => {
            state.loading = false;
            state.formError = action.payload?.message || null;
        })
        .addCase(loadGoogleAuthenticatorSecret.pending, (state, action) => {
            state.loading = true;
        });
});