import {createReducer} from "@reduxjs/toolkit";
import {nextStep, switchLoginMode} from "../../action/login";
import {MODE_LOADING, MODE_LOGIN_FORM} from "../../constant/login";

const initialState = {
    mode: MODE_LOGIN_FORM
};

export const loginReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(switchLoginMode, (state, action) => {
            state.mode = action.payload;
        })
        .addCase(nextStep.pending, (state, action) => {
            state.mode = MODE_LOADING;
        })
        .addCase(nextStep.rejected, (state, action) => {
            state.mode = MODE_LOGIN_FORM;
        });
});