export const MODE_LOADING = 'loading';
export const MODE_AUTHENTICATED = 'authenticated';
export const MODE_LOGIN_FORM = 'login_form';
export const MODE_GA_2FA_FORM = 'ga_2fa';
export const MODE_GA_2FA_SETUP = 'ga_2fa_setup';
export const MODE_PASSWORD_SETUP = 'setup_password';

export const MODE_2FA = '2fa';
export const MODE_2FA_SETUP = 'setup_2fa';

export const TYPE_GA = 'ga';