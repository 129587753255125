import React from "react";
import {Visible} from "./visible";

export const LoadingIndicator = (props) => (
    <Visible visible={props.visible}>
        <div className="loading">
            <div className="spinner-border text-success"  role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    </Visible>
)