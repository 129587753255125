import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import Config from "../../../../common/config";
import convertErrors from "../../../../../common/tools/convert.errors";

export const loadGoogleAuthenticatorSecret = createAsyncThunk('ga/setup/load-secret', async (data, thunkAPI) => {
    try {
        const resp = await axios.get(Config.path.login.secret_2fa, {
            params: data
        });

        return resp.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(convertErrors(err?.response?.data));
    }
});

export const performSetupGoogleAuthenticator = createAsyncThunk('ga/setup/perform', async (data, thunkAPI) => {
    try {
        const resp = await axios.post(Config.path.login.setup_2fa, data);

        if (resp.data.authenticated) {
            location.href = Config.path.panel_index;
        }

        return resp.data;
    } catch (err) {
        return thunkAPI.rejectWithValue(convertErrors(err?.response?.data));
    }
});