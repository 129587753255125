import React from 'react'
import InputField from "../../../../../common/component/fields/input.field";
import {_t} from "../../../../../../js/translation";

export const LoginFormComponent = (props) => (
    <section className="login-form__wrapper login-form--login">
        <div className="login-form__logo">
            <picture className="login-form__logo-picture">
                <svg width="111" height="91" viewBox="0 0 111 91" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.33949 75.3039C8.23866 75.3039 10.1367 73.7188 10.1367 71.2993C10.1367 68.7756 7.88409 67.9621 5.29777 67.107C3.21204 66.4187 1.73116 65.8764 1.73116 64.1244C1.73116 62.4975 3.00346 61.3086 5.02663 61.3086C7.11236 61.3086 8.23866 62.5184 8.78095 63.8324L9.71954 63.311C9.01039 61.6215 7.42522 60.2449 5.02663 60.2449C2.5446 60.2449 0.604864 61.7675 0.604864 64.1661C0.604864 66.7107 2.79489 67.399 5.2352 68.1916C7.46694 68.9007 9.01039 69.5682 9.01039 71.3202C9.01039 72.9888 7.7798 74.2402 5.33949 74.2402C2.94089 74.2402 1.46002 73.0513 0.959439 71.2993L0 71.8625C0.667436 73.9274 2.60717 75.3039 5.33949 75.3039Z" fill="#fefefe"/>
                    <path d="M13.1656 62.1221C13.6244 62.1221 13.9999 61.7467 13.9999 61.2878C13.9999 60.8498 13.6244 60.4744 13.1656 60.4744C12.7067 60.4744 12.3313 60.8498 12.3313 61.2878C12.3313 61.7467 12.7067 62.1221 13.1656 62.1221ZM12.6441 75.0745H13.687V64.6458H12.6441V75.0745Z" fill="#fefefe"/>
                    <path d="M28.039 64.4164C26.6415 64.4164 25.3067 65.0838 24.5558 66.5438C24.0135 65.1673 22.8246 64.4164 21.3021 64.4164C19.9672 64.4164 18.7157 65.0004 17.944 66.4396V64.6458H16.9012V75.0745H17.944V69.2553C17.944 66.5856 19.4666 65.4176 21.1769 65.4176C22.7829 65.4176 23.8467 66.4813 23.8467 68.4627V75.0745H24.8895V69.2553C24.8895 66.5856 26.2661 65.4176 28.0181 65.4176C29.645 65.4176 30.7922 66.4813 30.7922 68.4627V75.0745H31.835V68.4627C31.835 65.8556 30.2499 64.4164 28.039 64.4164Z" fill="#fefefe"/>
                    <path d="M40.3119 64.4164C38.2888 64.4164 36.6827 65.4593 35.8276 67.0653V64.6458H34.7847V79.246H35.8276V72.6551C36.6827 74.2611 38.2888 75.3039 40.3119 75.3039C43.2945 75.3039 45.714 72.9471 45.714 69.8602C45.714 66.7733 43.2945 64.4164 40.3119 64.4164ZM40.2493 74.3028C37.7673 74.3028 35.8276 72.3422 35.8276 69.8602C35.8276 67.3781 37.7673 65.4176 40.2493 65.4176C42.7314 65.4176 44.6711 67.3781 44.6711 69.8602C44.6711 72.3422 42.7314 74.3028 40.2493 74.3028Z" fill="#fefefe"/>
                    <path d="M48.2279 75.0745H49.2708V59.8486H48.2279V75.0745Z" fill="#fefefe"/>
                    <path d="M60.1188 64.6458L56.4896 73.9274L52.2764 64.6458H51.1501L55.9473 75.2622L55.7179 75.8254C55.0713 77.348 54.0076 78.3908 52.6101 78.2657V79.246C54.4038 79.392 55.9473 78.0363 56.7399 76.034L61.2451 64.6458H60.1188Z" fill="#fefefe"/>
                    <path d="M74.5472 60.4744L69.9168 68.1916L65.2656 60.4744H63.0756V75.0745H65.0154V63.6864L69.7917 71.6122H70.042L74.8183 63.6655V75.0745H76.7372V60.4744H74.5472Z" fill="#fefefe"/>
                    <path d="M89.6831 70.6945C89.7248 70.4233 89.7457 70.1522 89.7457 69.881C89.7457 68.3584 89.266 67.0653 88.2857 66.0016C87.3262 64.917 86.0748 64.3747 84.5522 64.3747C82.9462 64.3747 81.6322 64.8961 80.6102 65.939C79.5882 66.9819 79.0876 68.2959 79.0876 69.8602C79.0876 71.4453 79.609 72.7593 80.631 73.8022C81.6739 74.8242 83.0296 75.3457 84.6773 75.3457C86.7214 75.3457 88.244 74.5531 89.266 72.9888L87.7225 72.1128C87.0968 73.1348 86.0956 73.6354 84.7191 73.6354C82.7376 73.6354 81.2985 72.5508 80.9439 70.6945H89.6831ZM84.5522 66.085C86.2208 66.085 87.5974 67.1279 87.9102 69.1093H80.9439C81.2567 67.2739 82.6125 66.085 84.5522 66.085Z" fill="#fff"/>
                    <path d="M101.823 70.6945C101.864 70.4233 101.885 70.1522 101.885 69.881C101.885 68.3584 101.406 67.0653 100.425 66.0016C99.4659 64.917 98.2144 64.3747 96.6918 64.3747C95.0858 64.3747 93.7718 64.8961 92.7498 65.939C91.7278 66.9819 91.2272 68.2959 91.2272 69.8602C91.2272 71.4453 91.7486 72.7593 92.7707 73.8022C93.8135 74.8242 95.1693 75.3457 96.817 75.3457C98.861 75.3457 100.384 74.5531 101.406 72.9888L99.8622 72.1128C99.2364 73.1348 98.2353 73.6354 96.8587 73.6354C94.8773 73.6354 93.4381 72.5508 93.0835 70.6945H101.823ZM96.6918 66.085C98.3604 66.085 99.737 67.1279 100.05 69.1093H93.0835C93.3964 67.2739 94.7521 66.085 96.6918 66.085Z" fill="#fefefe"/>
                    <path d="M109.339 64.6458H106.565V61.7258L104.75 62.2681V64.6458H102.685V66.3978H104.75V72.0502C104.75 74.6365 106.169 75.4917 109.339 75.0745V73.4476C107.483 73.5311 106.565 73.6562 106.565 72.0502V66.3978H109.339V64.6458Z" fill="#fefefe"/>
                    <path d="M88.2244 90.8627C88.5894 90.8627 88.8814 90.5707 88.8814 90.2057C88.8814 89.8407 88.5894 89.5487 88.2244 89.5487C87.8448 89.5487 87.5674 89.8407 87.5674 90.2057C87.5674 90.5707 87.8448 90.8627 88.2244 90.8627Z" fill="#fff"/>
                    <path d="M98.6911 83.256C97.7129 83.256 96.7785 83.7232 96.2529 84.7452C95.8733 83.7816 95.0411 83.256 93.9753 83.256C93.0408 83.256 92.1648 83.6648 91.6246 84.6722V83.4166H90.8946V90.7167H91.6246V86.6432C91.6246 84.7744 92.6904 83.9568 93.8877 83.9568C95.0119 83.9568 95.7565 84.7014 95.7565 86.0884V90.7167H96.4865V86.6432C96.4865 84.7744 97.4501 83.9568 98.6765 83.9568C99.8153 83.9568 100.618 84.7014 100.618 86.0884V90.7167H101.348V86.0884C101.348 84.2634 100.239 83.256 98.6911 83.256Z" fill="#fff"/>
                    <path d="M106.698 83.256C104.464 83.256 102.917 84.9204 102.917 87.0666C102.917 89.2712 104.508 90.8773 106.757 90.8773C108.202 90.8773 109.341 90.2203 109.939 89.2274L109.297 88.8478C108.874 89.6217 107.968 90.1765 106.771 90.1765C105.107 90.1765 103.851 89.096 103.676 87.4462H110.29C110.29 87.3294 110.304 87.2126 110.304 87.0958C110.304 85.0956 108.917 83.256 106.698 83.256ZM106.698 83.9568C108.129 83.9568 109.385 84.9496 109.545 86.7308H103.676C103.822 85.1102 105.034 83.9568 106.698 83.9568Z" fill="#fff"/>
                    <path d="M55.2352 3.26615C55.5624 5.0989 54.3423 6.84995 52.51 7.17722C49.4612 7.7218 46.5713 9.25678 44.1956 11.6331C37.8319 17.9985 37.8319 28.1281 44.1956 34.4935C50.5593 40.8589 60.6863 40.8589 67.05 34.4935L67.084 34.4594L67.119 34.4264C69.0404 32.6113 70.3958 30.1835 71.2067 27.6341C71.7711 25.86 73.6664 24.8794 75.44 25.4439C77.2136 26.0084 78.194 27.9042 77.6296 29.6783C76.5586 33.0451 74.6912 36.5326 71.7823 39.2944C62.7842 48.259 48.4143 48.2478 39.4296 39.2608C30.4336 30.2625 30.4336 15.8641 39.4296 6.86585C42.7126 3.58191 46.8289 1.34336 51.3252 0.540249C53.1575 0.212973 54.908 1.4334 55.2352 3.26615Z" fill="#06ADEF"/>
                    <path d="M59.7301 3.86732C60.4134 2.146 62.3916 1.29326 64.1487 1.96266C67.1027 3.0881 69.676 4.83808 71.8756 6.99296C74.7395 9.79859 76.6882 13.1137 77.668 16.6331C78.164 18.4149 77.0917 20.2533 75.2729 20.7392C73.4541 21.2252 71.5776 20.1747 71.0816 18.3928C70.4237 16.0297 69.0972 13.7296 67.0482 11.7222C65.4267 10.1337 63.6331 8.94238 61.6743 8.1961C59.9172 7.5267 59.0468 5.58864 59.7301 3.86732Z" fill="#FEC439"/>
                </svg>
            </picture>
        </div>

        <div className="login-form__content">
            <form onSubmit={props.performLogin}>
                {props.formError &&
                <div className="alert alert-danger">{props.formError}</div>
                }

                <InputField name="login" label="login" errors={props.errors.login} />
                <InputField type="password" name="password" label="password" errors={props.errors.password} />

                <div className="login-form__btn-bar">
                    <button disabled={props.loading} type="submit" className="btn btn--blue btn--continue-login">
                        <i className="fal ico fa-sign-in-alt"></i>
                        <span className={'txt'}>{_t('Login')}</span>
                    </button>
                </div>

            </form>
        </div>
    </section>
)