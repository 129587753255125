import {createReducer} from "@reduxjs/toolkit";
import {performGoogleAuthentication} from "../action/ga.form";
import {buildFormReducer, formInitialState} from "../../../../../common/tools/form/form.reducer.builder";

const initialState = {
    ...formInitialState()
};

export const gaFormReducer = createReducer(initialState, (builder) => {
    buildFormReducer(builder, performGoogleAuthentication);
});