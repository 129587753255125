import qs from 'qs';
import _ from 'lodash';

const formData = (event) => {
    event.preventDefault();
    let res = {};
    let formData = new FormData(event.target);
    for (let pair of formData.entries()) {
        if (pair[1]) {
            // encodeURIComponent for case when value contains &
            let item = qs.parse(pair[0] + '=' + encodeURIComponent(pair[1]));

            _.mergeWith(res, item, (objValue, srcValue) => {
                if (_.isArray(objValue)) {
                    return objValue.concat(srcValue);
                }
            });
        }
    }
    return res;
}

export default formData;