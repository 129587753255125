import React from 'react'
import InputField from "../../../../../common/component/fields/input.field";
import {_t} from "../../../../../../js/translation";

export const PasswordSetupFormComponent = (props) => (
    <section className="login-form__wrapper login-form--password-setup">
        <div className="login-form__content">
            <form onSubmit={props.performChangePassword}>
                <p className={"login-form__text p"}>{_t('Change password')}</p>
                {props.formError &&
                <div className="alert alert-danger">{props.formError}</div>
                }

                <InputField name="password" type="password" label="password" errors={props.errors.password} />
                <InputField name="repeatPassword" type="password" label="Repeat password" errors={props.errors.repeatPassword} />

                <div className="login-form__btn-bar">
                    <button disabled={props.loading} type="submit" className="btn btn--green btn--continue-login">{_t('Continue')}</button>
                    <button type="button" className="btn btn-link btn-link--grey" onClick={props.back}>{_t('Back')}</button>
                </div>
            </form>
        </div>
    </section>
)