import React from "react";
import {GaSetupComponent} from "./ga.setup";

export class GaSetupWrapperComponent extends React.Component {

    componentDidMount() {
        this.props.loadSecret({
            type: 'ga'
        });
    }

    render(props) {
        return <GaSetupComponent {...this.props} />
    }

}