export const formInitialState = function () {
    return {
        model: null,
        loading: false,
        errors: {},
        formError: null,
        formKey: Math.random() * 1000
    }
};

export const buildFormReducer = function (builder, saveAction, loadAction, options = {}) {
    builder
        .addCase(saveAction.fulfilled, (state, action) => {
            state.loading = false;
            state.errors = {};
            state.formError = null;
            if (options.assignAfterSave) {
                state.model = action.payload;
            }
        })
        .addCase(saveAction.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.payload.fields || {};
            state.formError = action.payload.message || null;
        })
        .addCase(saveAction.pending, (state, action) => {
            state.loading = true;
        });
    if (loadAction) {
        builder
            .addCase(loadAction.fulfilled, (state, action) => {
                state.model = action.payload;
                state.loading = false;
                state.errors = {};
                state.formError = null;
                state.formKey = Math.random() * 1000;
            })
            .addCase(loadAction.rejected, (state, action) => {
                state.loading = false;
                state.formError = action.payload.message || null;
                state.formKey = Math.random() * 1000;
            })
            .addCase(loadAction.pending, (state, action) => {
                state.model = null;
                state.loading = true;
                state.formKey = Math.random() * 1000;
            });
    }
}