import axios from 'axios';
import Config from "./config";
import qs from 'qs';

axios.defaults.headers.common['X-CSRF-TOKEN'] = Config.csrf_token;

axios.interceptors.request.use(config => {
    config.paramsSerializer = params => {
        return qs.stringify(params, {
            arrayFormat: 'brackets',
            encode: false
        });
    };

    return config;
});