import {connect} from "react-redux";
import {switchLoginMode} from "../../../action/login";
import formData from "../../../../../common/tools/form.data";
import {performPasswordSetup} from "../action/password_setup.form";
import {MODE_LOGIN_FORM} from "../../../constant/login";
import {PasswordSetupFormComponent} from "../component/password_setup.form";

const mapDispatchToProps = (dispatch, ownProps) => ({
    performChangePassword: (event) => {
        dispatch(performPasswordSetup(formData(event)))
    },
    back: () => {
        dispatch(switchLoginMode(MODE_LOGIN_FORM))
    }
});

const mapStateToProps = (state, ownProps) => ({
    ...state.passwordSetupFormReducer
});

export const PasswordSetupForm = connect(
    mapStateToProps, mapDispatchToProps
)(PasswordSetupFormComponent);