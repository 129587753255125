import React from 'react'
import {LoginForm} from "../modules/login/container/login.form";
import {GaForm} from "../modules/ga/container/ga.form";
import {GaSetup} from "../modules/ga/container/ga.setup";
import {PasswordSetupForm} from "../modules/password_setup/container/password_setup.form";
import {
    MODE_GA_2FA_FORM,
    MODE_GA_2FA_SETUP,
    MODE_LOADING,
    MODE_LOGIN_FORM,
    MODE_PASSWORD_SETUP
} from "../constant/login";
import {LoadingIndicator} from "../../../common/component/loading.indicator";

export const LoginComponent = (props) => (
    <React.Fragment>
        <div className="header--phantom"></div>
        <div className="container">
            {props.mode === MODE_LOADING && <LoadingIndicator visible={true}/>}
            {props.mode === MODE_LOGIN_FORM && <LoginForm/>}
            {props.mode === MODE_PASSWORD_SETUP && <PasswordSetupForm/>}
            {props.mode === MODE_GA_2FA_FORM && <GaForm/>}
            {props.mode === MODE_GA_2FA_SETUP && <GaSetup/>}
        </div>
        <footer className={'footer--phantom'}></footer>
    </React.Fragment>
)