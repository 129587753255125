import {MODE_LOGIN_FORM} from "../../../constant/login";
import {connect} from "react-redux";
import {loadGoogleAuthenticatorSecret, performSetupGoogleAuthenticator} from "../action/ga.setup";
import {switchLoginMode} from "../../../action/login";
import {GaSetupWrapperComponent} from "../component/ga.setup.wrapper";
import formData from "../../../../../common/tools/form.data";

const mapDispatchToProps = (dispatch, ownProps) => ({
    loadSecret: (data) => {
        dispatch(loadGoogleAuthenticatorSecret(data))
    },
    performSetup: (event) => {
        dispatch(performSetupGoogleAuthenticator(formData(event)))
    },
    back: () => {
        dispatch(switchLoginMode(MODE_LOGIN_FORM))
    }
});

const mapStateToProps = (state, ownProps) => ({
    ...state.gaSetupReducer
});

export const GaSetup = connect(
    mapStateToProps, mapDispatchToProps
)(GaSetupWrapperComponent);